<template>
  <div class="text-2xl font-bold text-invest-primary">Reinvest</div>
  <p class="mt-2 text-xs text-invest-dark">
    How long do you want to reinvest for?
  </p>
  <form-input
    type="select"
    :form="form"
    name="investment_tenure"
    :options="tenureOptions"
    defaultValue="Select Tenure"
    v-model="form.data.investment_tenure.value"
  />

  <form-input
    type="currency"
    placeholder="Investment amount"
    name="investment_amount"
    :form="form"
    :disabled="true"
    v-model="form.data.investment_amount.value"
  />

  <div class="mt-12">
    <h3 class="text-xs font-semibold">Investment Breakdown</h3>

    <div class="investment-details">
      <p class="text-xs">Principal amount</p>
      <span class="text-xs text-invest-primary">
        {{ $filters.formatAmount(investmentBreakdown.amount) }}
      </span>
    </div>

    <div class="investment-details">
      <p class="text-xs">Interest rate</p>
      <span class="text-xs text-invest-primary">
        {{ investmentBreakdown.interest_rate }}% annually
      </span>
    </div>

    <div class="investment-details">
      <p class="text-xs">Potential earnings</p>
      <span class="text-xs text-invest-primary">
        {{ $filters.formatAmount(investmentBreakdown.potential_earnings) }}
      </span>
    </div>

    <div class="investment-details">
      <p class="text-xs">Maturity date</p>
      <span class="text-xs text-invest-primary">
        {{ investmentBreakdown.maturity_date }}
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  SetupContext,
  onBeforeMount,
  computed,
  reactive,
  toRefs,
  PropType,
  onMounted,
} from 'vue';
import { TenureProp } from '@/types/Investment';
import resource from '@/modules/resource';
import investmentModule from '@/modules/investment/investmentModule';
import { InvestmentProp } from '@/types/Investment';
import { format, addDays } from 'date-fns';
//import filters from '@/helpers/filters';
export default defineComponent({
  inheritAttrs: false,
  props: {
    investment: {
      type: Object as PropType<InvestmentProp>,
      default: () => ({} as InvestmentProp),
    },
  },
  setup: (props, { attrs }: SetupContext) => {
    const { basicForm } = resource();

    const { investment } = toRefs(props);

    const { tenures, getAllTenures } = investmentModule();

    //const { percentage } = filters;

    const data = reactive({
      form: basicForm([
        {
          name: 'investment_amount',
          rules: 'required',
        },
        {
          name: 'investment_tenure',
          rules: 'required',
        },
      ]),
    });

    const tenureOptions = computed(() =>
      tenures.value?.map((tenure: TenureProp) => {
        return {
          title: `${tenure?.days} Days`,
          value: tenure?.id,
        };
      }),
    );

    const investmentBreakdown = computed(() => {
      const tenure: any = tenures.value?.find(
        (prop: any) => prop?.id === data.form.data.investment_tenure.value,
      );

      const amount = data.form.data.investment_amount.value;
      const potentialEarnings =
        amount * (tenure?.percentage / 100) * (tenure?.days / 365);

      return {
        potential_earnings: potentialEarnings || 0,
        amount: amount,
        interest_rate: tenure?.percentage || 0,
        maturity_date: format(
          addDays(new Date(), tenure?.days ?? 30),
          'do MMMM, yyyy',
        ),
      };
    });

    const percentage = (value: any) => value?.toFixed(2);

    onBeforeMount(getAllTenures);

    onMounted(() => {
      const amount = investment.value?.earnings ?? investment.value?.amount;
      data.form.data.investment_amount.value = percentage(amount);
    });

    return { ...toRefs(data), tenureOptions, investmentBreakdown, percentage };
  },
});
</script>
